import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Icon } from '@iconify/react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Menu from '../components/navigation';
import Gallery from '../components/gallery';
import Dots from '../components/dots';

/**
 * Footer image
 */
const FooterImage = () => {
	const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "L1008206.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1600) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	if (!data?.placeholderImage?.childImageSharp?.fluid) {
		return null;
	}

	return (
		<div className="index-image">
			<Img fluid={data.placeholderImage.childImageSharp.fluid} />
			<div className="text">
				<div className="page-width">
					<p>Ich weiss ganz genau, wo meine stärken liegen.</p>
					<p>Für alles weitere habe ich zuverläßige Partner.</p>
				</div>
			</div>
			<div className="contact">
				<Link to="/kontakt/" className="button">
					<Icon icon="vb:send" />
					Anfrage Senden
				</Link>
			</div>
		</div>
	);
};

/**
 * Page
 */
const IndexPage = () => {
	const sliderText = 'Corporate\nDesign &\nMarkenaufbau';

	return (
		<Layout slider="start" hasDots={true} sliderText={sliderText}>
			<SEO title="" />
			<Menu selected="start" />
			<div className="page-width page-width--wider align-center big-logo">
				<Icon icon="vb:big-logo" height="auto" />
				<Icon icon="vb:big-logo-text" height="auto" />
			</div>
			<div className="page-width align-center">
				<p className="text-huge spacing-normal">
					Die Einzigartigkeit Ihres Unternehmens Sichtbar Zu Machen,
					Darin Sehe Ich Meine Aufgabe.
				</p>
				<p className="spacing-normal">
					Ihr Unternehmen verkörpert Leistung, Qualität und Werte -
					durch Ihre Mitarbeiter, Ihr tägliches Handeln und Ihr
					Produkt können Sie diese Aspekte nach innen leben und nach
					außen spiegeln.
				</p>
				<div className="split spacing-big text-subheadline image-links">
					<div>
						<Link to="/leistungen/#cd">
							<Icon icon="vb:cd" height="auto" />
						</Link>
						<p>Corporate Design / Relaunch</p>
					</div>
					<div>
						<Link to="/leistungen/#dp">
							<Icon icon="vb:dp" height="auto" />
						</Link>
						<p>Digital- &amp; Printdesign</p>
					</div>
					<div>
						<Link to="/leistungen/#ma">
							<Icon icon="vb:ma" height="auto" />
						</Link>
						<p>Marken Aufbau</p>
					</div>
				</div>
				<Dots />
				<div className="anchor">
					<div id="projekte" />
				</div>
				<p className="spacing-big text-subheadline text-subheadline--light">
					Mit Bildern Ausdrücken, Wofür Worte nicht reichen:
				</p>
				<Gallery />
				<div className="spacing-big">
					<Icon icon="vb:big-logo" height="auto" />
				</div>
			</div>
			<FooterImage />
			<div className="page-width contact-buttons contact-buttons--index">
				<a
					href="https://www.facebook.com/vbgrafikdesign/"
					target="_blank"
					rel="noreferrer"
				>
					<Icon icon="vb:facebook-button" />
				</a>
				<a
					href="https://www.instagram.com/vb_grafikdesign/?hl=de"
					target="_blank"
					rel="noreferrer"
				>
					<Icon icon="vb:instagram-button" />
				</a>
			</div>
		</Layout>
	);
};

export default IndexPage;
